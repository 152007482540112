import React, { useId, useState } from 'react';
import Dropzone from 'react-dropzone';
import { db, storage } from '../firebase';
import firebase from 'firebase/compat/app';
import '../style/New_Event.css';
import showAlert from './comps/alert';
import { v4 as uuidv4 } from 'uuid';


const New_Event = () => {
  const [eventName, setEventName] = useState('');
  const [eventTime, setEventTime] = useState('');
  const [eventPriceOnline1, setEventPriceOnline1] = useState('');
  const [eventPriceOnline2, setEventPriceOnline2] = useState('');
  const [eventPriceOnline3, setEventPriceOnline3] = useState('');
  const [eventPlace, setEventPlace] = useState('');
  const [online1_db, setEventdb1] = useState();
  const [online2_db, setEventdb2] = useState();
  const [online3_db, setEventdb3] = useState();
  const [eventImage, setEventImage] = useState(null);
  const [eventActiveInactive, setEventActiveInactive] = useState("Aktív");

  const [id, setEventId] = useState('');
  const handleDrop = (acceptedFiles) => {
    setEventImage(acceptedFiles[0]);
  };

  const addNewEvent = async (eventData) => {
    try {
      const id = uuidv4();
      const docRef = await db.collection('events').doc(id).set({ ...eventData, id });
      
      console.log('Esemény sikeresen hozzáadva a Firestore adatbázishoz.');
      showAlert('Sikeresen létrehoztad az eseményt!','my-alert-succes')
    } catch (error) {
      console.error('Hiba történt az esemény hozzáadása közben:', error);
      showAlert('Hiba történt az esemény hozzáadása közben','my-alert-error')
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const eventObject = {
        eventName,
        eventTime,
        eventPriceOnline1,
        eventPriceOnline2,
        eventPriceOnline3,
        online1_db,
        online2_db,
        online3_db,
        eventPlace,
        eventActiveInactive,
        ticketCount:0,
        id,
        
      };

      if (eventImage) {
        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child(`event_images/${eventImage.name}`);
        
        await imageRef.put(eventImage);
        const imageUrl = await imageRef.getDownloadURL();
        eventObject.eventImage = imageUrl;
        
      }

      await addNewEvent(eventObject);

      setEventName('');
      setEventTime('');
      
      setEventPriceOnline1('');
      setEventPriceOnline2('');
      setEventPriceOnline3('');
      setEventdb1('');
      setEventdb2('');
      setEventdb3('');



      setEventPlace('');
      setEventImage(null);
      setEventActiveInactive("Aktív");

    } catch (error) {
      showAlert('Hiba történt az esemény hozzáadása közben:','my-alert-error')
      console.error('Hiba történt az esemény hozzáadása közben:', error);
    }
  };

  return (
    <div class="container">
    <form onSubmit={handleSubmit} className="form-container">
      <label htmlFor="eventName">Esemény neve:</label>
      <input type="text" id="eventName" value={eventName} onChange={(e) => setEventName(e.target.value)} required /><br />

      <label htmlFor="eventTime">Esemény időpontja:</label>
      <input type="datetime-local" id="eventTime" value={eventTime} onChange={(e) => setEventTime(e.target.value)} required /><br />

      
      <label htmlFor="eventPrice">Esemény ára online 1. elővételes:</label>
      <input type="number" id="eventPrice" value={eventPriceOnline1} onChange={(e) => setEventPriceOnline1(parseInt(e.target.value))} required /><br />
      <label htmlFor="eventPrice">Eseményre eladható Elővételes 1. jegy db száma:</label>
      <input type="number" id="online1_db" value={online1_db} onChange={(e) => setEventdb1(parseInt(e.target.value))} required /><br />

      <label htmlFor="eventPrice">Esemény ára online 2. elővételes:</label>
      <input type="number" id="eventPrice" value={eventPriceOnline2} onChange={(e) => setEventPriceOnline2(parseInt(e.target.value))} required /><br />

      <label htmlFor="eventPrice">Eseményre eladható Elővételes 2. jegy db száma:</label>
<input type="number" id="online2_db" value={online2_db} onChange={(e) => setEventdb2(parseInt(e.target.value))} required /><br />


      <label htmlFor="eventPrice">Esemény ára online 3. elővételes:</label>
      <input type="number" id="eventPrice" value={eventPriceOnline3} onChange={(e) => setEventPriceOnline3(parseInt(e.target.value))} required /><br />

      <label htmlFor="eventPrice">Eseményre eladható Elővételes 3. jegy db száma:</label>
<input type="number" id="online3_db" value={online3_db} onChange={(e) => setEventdb3(parseInt(e.target.value))} required /><br />

      <label htmlFor="eventPlace">Esemény helyszíne:</label>
      <input type="text" id="eventPlace" value={eventPlace} onChange={(e) => setEventPlace(e.target.value)} required /><br />

      <label htmlFor="eventImage">Esemény képe:</label>
      <Dropzone onDrop={handleDrop} accept="image/*" multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="dropzone-container">
            <input {...getInputProps()} />
            <p className="dropzone-text">Húzd ide a képet, vagy kattints a feltöltéshez.</p>
            {eventImage && <p className="uploaded-image">Feltöltött kép: {eventImage.name}</p>}
          </div>
        )}
      </Dropzone>

      <button type="submit" className="btn btn-outline-success">Mentés</button>
    </form>
    </div>
  );
};

export default New_Event;
