import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
//import { BrowserRouter,Routes,Route} from "react-router-dom";
import { auth, db } from "../firebase";
import "../style/SignIn.css";
import logo from "../img/Képernyőkép_2023-10-08_152223-removebg-preview.png";
import { doc, getDoc } from "firebase/firestore";




 // Alapértelmezett érték




const SignIn = () => {
  

  




  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();
  

  const signIn = async(e) => {
    e.preventDefault();
    try {
      // Firebase bejelentkezés
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
  
      // A felhasználó UID-jének lekérése
      const userUid = userCredential.user.uid;
  
      // Firebase Firestore-ból a felhasználó dokumentumának lekérése
      const userDocRef = doc(db, "users", userUid);
      const userDocSnapshot = await getDoc(userDocRef);
  
      if (userDocSnapshot.exists() && userDocSnapshot.data().confirmed === true) {
        // A felhasználó létezik a Firestore-ban és engedélyezett
        navigate("/Dashboard");

      } 
      else if(userDocSnapshot.exists() && userDocSnapshot.data().confirmed === false)
      {
        navigate("/Success?i=confirm");
      }
      
      
     
    } catch (error) {
      console.error("Hiba a bejelentkezés során:", error);
      alert("Hibás e-mail cím vagy jelszó!");
    }
  };
  
  return (
<div className='sign-in-container'>
  <div className="sign-in">
  <div className='logo'>
    <img src={logo} alt='Logo'  id="logo"/>
  </div>
  <form onSubmit={signIn}>
    <h1>Promóteri Bejelentkezés</h1>
    <input

      type='email'
      placeholder='Email'
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      required
    />
    <input

      type='password'
      placeholder='Password'
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      required 
      
    />
    <button type='submit'>Login</button>
  </form>
  <button variant="outline-primary" onClick={() => navigate("/")}>
    Vissza a kezdőlapra
  </button>
  </div>
</div>

  );
};


export default SignIn;

