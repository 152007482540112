import React, { useEffect, useState } from 'react';
import { db, storage } from '../firebase';
import '../style/TicketActivate.css';

import QrReader from 'react-qr-scanner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import showAlert from './comps/alert';


const TicketActivate = () => {
  const [activationCode, setActivationCode] = useState('');
  const [ticketStatus, setTicketStatus] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [eventName, setEventName] = useState('');
  const [events, setEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState('');


  useEffect(() => {
    const fetchActiveEvents = async () => {
      try {
        const eventsRef = db.collection('events').where('eventActiveInactive', '==', 'Aktív');
        const snapshot = await eventsRef.get();
        const activeEvents = snapshot.docs.map((doc) => doc.data());
        setEvents(activeEvents);
      } catch (error) {
        console.error('Hiba történt az aktív események lekérdezése közben:', error);
      }
    };
  
    fetchActiveEvents();
  }, []);
  

  

  const handleActivation = async () => {
    if(selectedEventId===eventName)
    {
    if (!activationCode) {
      
      return;
    }

    try {
      
      const ticketsRef = db.collection('tickets');
      const querySnapshot = await ticketsRef.where('ticketId', '==', activationCode).get();

      if (!querySnapshot.empty) {
        const ticketDoc = querySnapshot.docs[0];
        const ticketData = ticketDoc.data();

        if (ticketData.eventActiveInactive === 'Aktív') {
          if (ticketData.activationTime) {
            const activationDate = new Date(ticketData.activationTime).toLocaleString();
            setTicketStatus(`A jegy már aktiválva lett ${activationDate}-kor.`);
            showAlert('A jegy már aktiválva lett.', 'alert-error');
          } else {
            setTicketStatus('A jegy már aktiválva lett, de az aktiválás időpontja ismeretlen.');
          }
          
        } else {
          // Megerősítő ablak megjelenítése
          const userConfirmed = window.confirm('Biztosan aktiválni szeretné ezt a jegyet?');

          if (userConfirmed) {
            // Jegy aktiválása
            await ticketDoc.ref.update({
              eventActiveInactive: 'Aktív',
              activationTime: new Date().toISOString(),
            });
        
            
            
        
           
            setTicketStatus('A jegy sikeresen aktiválva lett.');
            showAlert('A jegy sikeresen aktiválva.', 'my-alert-success');
            setActivationCode('');
          } else {
            setTicketStatus('A jegy aktiválása megszakítva.');
          }
        
        }
      } else {
        setTicketStatus('Érvénytelen kód.');
        showAlert('Érvénytelen kód.', 'my-alert-error');
      }
    } catch (error) {
      console.error('Hiba történt:', error);
      setTicketStatus('Hiba történt. Kérlek próbáld újra később.');
    }}
    else{
     await setShowCamera(false);
      showAlert("nem erre a bulira szól")
      
    }
  };

  const handleScan = async (result) => {
    if (result) {
      const scannedCode = result.text;
      let parsedData;
      try {
        parsedData = JSON.parse(scannedCode); // A QR kód tartalmának elemzése
        const { ticketId, partyName } = parsedData;
        setEventName(partyName)
        if (ticketId) {
          setActivationCode(ticketId); // Jegy azonosító beállítása
          setShowCamera(false); // Kamera bezárása
          handleActivation(); // Jegy aktiválása
        } else {
          console.error('Érvénytelen QR kód tartalom: hiányzik a jegy azonosító.');
        }
      } catch (error) {
        console.error('Hiba történt a QR kód elemzése során:', error);
      }
    }
  };
  

  const handleError = (error) => {
    console.error('QR-kód beolvasás hiba:', error);
  };

  return (
    <div className="ticket-activate-container">
    <div className="ticketdivac">
      <h1 className="ticket-activate-heading">Jegy Aktiválás</h1>
      <div className="select-input-container">
        <select
          value={selectedEventId}
          onChange={(e) => setSelectedEventId(e.target.value)}
        >
          <option value="">Válassz aktív eseményt...</option>
          {events.map((event) => (
            <option key={event.id} value={event.id}>
              {event.eventName}
            </option>
          ))}
        </select>
      </div>
      <div className="input-container">
        <input
          className="activation-input"
          type="text"
          placeholder="Aktiváló kód"
          value={activationCode}
          onChange={(e) => setActivationCode(e.target.value)}
        />
      </div>
      <div className="button-container">
        <button className="camera-button" onClick={() => setShowCamera(!showCamera)}>
          <FontAwesomeIcon icon={faCamera} />
        </button>
        {showCamera && (
          <QrReader
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: '100%' }}
          />
        )}
      </div>
      <div className="button-container">
        <button className="activation-button" onClick={handleActivation}>
          Aktiválás
        </button>
      </div>
      {ticketStatus && (
        <p className="status-message">{ticketStatus}</p>
      )}
    </div>
  </div>
  
  );
};

export default TicketActivate;
