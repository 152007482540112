import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebase'; // Győződj meg arról, hogy az import utvonal megfelelő legyen

const ConfirmUsers = () => {
  const [unconfirmedUsers, setUnconfirmedUsers] = useState([]);

  useEffect(() => {
    const fetchUnconfirmedUsers = async () => {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('confirmed', '==', false));
      const querySnapshot = await getDocs(q);

      const unconfirmedUsersData = [];
      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        userData.id = doc.id;
        unconfirmedUsersData.push(userData);
      });

      setUnconfirmedUsers(unconfirmedUsersData);
    };

    fetchUnconfirmedUsers();
  }, []);

  const handleConfirmUser = async (userId) => {
    try {
      // Megerősítjük a felhasználót az adatbázisban
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        confirmed: true,
      });

      // Távolítjuk el a megerősített felhasználót a listából
      setUnconfirmedUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
    } catch (error) {
      console.error('Hiba történt a felhasználó megerősítése során:', error);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Megerősítetlen felhasználók</h2>
      {unconfirmedUsers.length > 0 ? (
        <ul className="list-group">
          {unconfirmedUsers.map((user) => (
            <li key={user.id} className="list-group-item d-flex justify-content-between align-items-center">
              <div>
                <strong>{user.displayName}</strong> - {user.email}
              </div>
              <button className="btn btn-success" onClick={() => handleConfirmUser(user.id)}>
                Megerősítés
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <p>Nincs megerősítésre váró felhasználó.</p>
      )}
    </div>
  );
};

export default ConfirmUsers;
