import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';

const ShowNavbar = ({children}) => {

const[showNavbar,setShowNavbar]=useState(false)

    const location= useLocation();

  useEffect(() => {
  //  console.log("location:", location)
    if( location.pathname==='/Register' || location.pathname==='/Login' || location.pathname==='/Success'){
         setShowNavbar(false)
         
     }
     else{
         setShowNavbar(true)
     }
  }, [location])



  return (
    <div>
      {showNavbar&&children}
    </div>
  )
}

export default ShowNavbar
