import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, deleteDoc, doc, updateDoc, getDocs, query, where } from 'firebase/firestore';
import { db, storage } from '../firebase';
import '../style/ShowEvents.css';
import { Button } from 'react-bootstrap';
import showAlert from './comps/alert';


const ShowEvents = () => {
  const [events, setEvents] = useState([]);
  const [editedEvent, setEditedEvent] = useState(null);
  const [newImage, setNewImage] = useState(null);
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'events'), async (snapshot) => {
      const eventData = [];
      for (const doc of snapshot.docs) {
        const event = doc.data();
        const ticketsRef = collection(db, 'tickets');
        const q = query(ticketsRef, where('partyName', '==', event.eventName));
        const querySnapshot = await getDocs(q);
        event.ticketCount = querySnapshot.size;
        eventData.push({ id: doc.id, ...event });
      }
      setEvents(eventData);
    });

    return () => unsubscribe();
  }, []);

  const handleDeleteEvent = async (id) => {
    try {
      await deleteDoc(doc(db, 'events', id));
      console.log('Esemény sikeresen törölve.');
      showAlert('Esemény sikeresen törölve.', 'my-alert-success');
    } catch (error) {
      console.error('Hiba történt az esemény törlése közben:', error);
    }
  };
  

  const handleEditEvent = (event) => {
    setEditedEvent(event);
    setNewImage(null);
  };

  const handleSaveEvent = async () => {
    try {
      let updatedEvent = { ...editedEvent };
      if (newImage) {
        const imageRef = storage.ref().child(newImage.name);
        await imageRef.put(newImage);
        const imageUrl = await imageRef.getDownloadURL();
        updatedEvent = { ...updatedEvent, eventImage: imageUrl };
      }

      await updateDoc(doc(db, 'events', editedEvent.id), updatedEvent);
      console.log('Esemény sikeresen módosítva.');
      showAlert('Esemény sikeresen módosítva.', 'my-alert-success');
      setEditedEvent(null);
      setNewImage(null);
    } catch (error) {
      console.error('Hiba történt az esemény módosítása közben:', error);
    }
  };

  const handleStatusChange = (status) => {
    setEditedEvent({ ...editedEvent, eventActiveInactive: status });
  };



  return (
    <div className="show-events-container">
      <h1>Események listája</h1>
      {events.map((event) => (
        <div key={event.id} className="event-item">
          {editedEvent?.id === event.id ? (
            <>
              <input
                type="text"
                value={editedEvent.eventName}
                onChange={(e) => setEditedEvent({ ...editedEvent, eventName: e.target.value })}
              />
              <input
                type="datetime-local"
                value={editedEvent.eventTime}
                onChange={(e) => setEditedEvent({ ...editedEvent, eventTime: e.target.value })}
              />
              <input
                type="number"
                value={editedEvent.eventPriceOnline1}
                onChange={(e) => setEditedEvent({ ...editedEvent, eventPriceOnline1: e.target.value })}
              />
              <input
                type="number"
                value={editedEvent.eventPriceOnline2}
                onChange={(e) => setEditedEvent({ ...editedEvent, eventPriceOnline2: e.target.value })}
              />
              <input
                type="number"
                value={editedEvent.eventPriceOnline3}
                onChange={(e) => setEditedEvent({ ...editedEvent, eventPriceOnline3: e.target.value })}
              />
              <input
                type="text"
                value={editedEvent.eventPlace}
                onChange={(e) => setEditedEvent({ ...editedEvent, eventPlace: e.target.value })}
              />
              <input type="file" onChange={(e) => setNewImage(e.target.files[0])} />
              <div className="status-dropdown">
                <label htmlFor="status">Állapot:</label>
                <select id="status" value={editedEvent.eventActiveInactive} onChange={(e) => handleStatusChange(e.target.value)}>
                  <option value="Aktív">Aktív</option>
                  <option value="Inaktív">Inaktív</option>
                </select>
              </div>
              <button onClick={handleSaveEvent} className="btn btn-outline-success">Mentés</button>
            </>
          ) : (
            <>
            <p className="event-info">Eladott jegyek: {event.ticketCount || 0}</p>
              <p>Esemény neve: {event.eventName}</p>
              <p>Esemény időpontja: {new Date(event.eventTime).toLocaleString()}</p>
              <p>Elővételes 1: {event.eventPriceOnline1} Ft.</p>
              <p>Elővételes 2: {event.eventPriceOnline2} Ft.</p>
              <p>Elővételes 3: {event.eventPriceOnline3} Ft.</p>
              <p>Esemény helyszíne: {event.eventPlace}</p>
              <p>Állapot: {event.eventActiveInactive}</p>
              <p>Esemény képe: <img src={event.eventImage} id='event_image'/></p>
              <br/>

              <div className="button-container">
                <button onClick={() => handleDeleteEvent(event.id)} className="btn btn-outline-danger">Törlés</button>
                <button onClick={() => handleEditEvent(event)} className="btn btn-outline-primary">Módosítás</button>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default ShowEvents;
