import React, { useState, useEffect } from 'react';
import { useHistory, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import "../style/Dashboard.css"

const Dashboard = () => {
  const [events, setEvents] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsRef = db.collection('events').where('eventActiveInactive', '==', 'Aktív');
        const snapshot = await eventsRef.get();
        
        const eventsData = snapshot.docs.map((doc) => doc.data());
        setEvents(eventsData);
      } catch (error) {
        console.error('Hiba történt az események lekérdezése közben:', error);
      }
    };

    fetchEvents();
  }, []);

  const handleEventClick = (eventId) => {
    // Irányítás a TicketPage.js-re és az esemény azonosítójának átadása az URL-ben
    navigate(`/TicketPage?id=${eventId}`);
  };

  return (
    <div className="dashboard-container">
      {events.map((event) => (
        <div key={event.id} className="event-card">
          <img src={event.eventImage} alt={event.eventName} />
          <h2>{event.eventName}</h2>
          <p>{event.eventDetails}</p>
          <p>{new Date(event.eventTime).toLocaleString()}</p>
          {event.isSoldOut ? (
            <p className="sold-out-ticket">Elkelt</p>
          ) : (
            <button onClick={() => handleEventClick(event.id)}>Részletek</button>
          )}
        </div>
      ))}
    </div>
  );
};

export default Dashboard;
