import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import '../style/TicketLogs.css';
import emailjs from '@emailjs/browser';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const TicketLogs = () => {
  const [ticketLogs, setTicketLogs] = useState([]);
  const [sortingOption, setSortingOption] = useState('time');
  const [filterSender, setFilterSender] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedEvent, setSelectedEvent] = useState('');
  const [eventNames, setEventNames] = useState([]);
  const [partnerShare, setPartnerShare] = useState(0.1); // 10% partner részesedés

  useEffect(() => {
    const fetchTicketLogs = async () => {
      try {
        const ticketLogsRef = db.collection('tickets');
        let query = ticketLogsRef;

        if (filterSender) {
          query = query.where('senderEmail', '==', filterSender);
        }

        if (selectedEvent) {
          query = query.where('partyName', '==', selectedEvent);
        }

        const snapshot = await query.get();
        const logsData = snapshot.docs.map((doc) => doc.data());

        if (sortingOption === 'time') {
          logsData.sort((a, b) => new Date(a.sold_date) - new Date(b.sold_date));
        } else if (sortingOption === 'event') {
          logsData.sort((a, b) => a.partyName.localeCompare(b.partyName));
        }

        setTicketLogs(logsData);
      } catch (error) {
        console.error('Hiba történt a jegyadatok lekérdezése közben:', error);
      }
    };

    fetchTicketLogs();
  }, [sortingOption, filterSender, selectedEvent]);

  const filterLogsByMonthAndEvent = () => {
    let filteredLogs = ticketLogs;

    if (selectedMonth) {
      filteredLogs = filteredLogs.filter(log => {
        const logMonth = new Date(log.sold_date).getMonth();
        return logMonth.toString() === selectedMonth;
      });
    }

    if (selectedEvent) {
      filteredLogs = filteredLogs.filter(log => log.partyName === selectedEvent);
    }

    return filteredLogs;
  };

  const totalTicketsSoldInMonthAndEvent = filterLogsByMonthAndEvent().length;
  const totalRevenueInMonthAndEvent = filterLogsByMonthAndEvent().reduce((total, log) => total + parseInt(log.ticketPrice), 0);
  const partnerRevenue = totalRevenueInMonthAndEvent * partnerShare;

 
  

  const handleMonthFilterChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const fetchEvents = async () => {
    try {
      const eventsRef = db.collection('events'); // Csak példa, helyettesítsd a megfelelő gyűjteménynévvel
      const snapshot = await eventsRef.get();
      const eventNames = snapshot.docs.map((doc) => doc.data().eventName);
      setEventNames(eventNames);
    } catch (error) {
      console.error('Hiba történt az események lekérdezése közben:', error);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleEventFilterChange = (e) => {
    setSelectedEvent(e.target.value);
  };

  const generatePDF = async(isExport) => {
    
    const filteredLogs = filterLogsByMonthAndEvent();

  const doc = new jsPDF();
  doc.text('Eladott Jegyek Naplója', 10, 10);

  const tableData = filteredLogs.map(log => [
    new Date(log.sold_date).toLocaleString(),
    log.partyName,
    log.senderEmail,
    log.email_to,
    `${log.ticketPrice} Ft`,
    log.eventActiveInactive,
  ]);

  tableData.push([
    'Eladott összes jegy',
    `${totalTicketsSoldInMonthAndEvent} db`,
    'Termelt össz bevétel',
    `${totalRevenueInMonthAndEvent} Ft`,
    'Részesedés',
    `${totalRevenueInMonthAndEvent*partnerShare} Ft`,
   
  ]);

  doc.autoTable({
    head: [['Időpont', 'Esemény neve', 'Küldő', 'Címzett', 'Jegy Ára', 'Állapot']],
    body: tableData,
  });

  const currentDate = new Date().toISOString().slice(0, 10);

  let fileName = `EladottJegyek_${currentDate}`;

  if (filterSender) {
    fileName += `_${filterSender}`;
  }
  if (selectedEvent) {
    fileName += `_${selectedEvent}`;
  }
  if (selectedMonth) {
    const monthNames = [
      'Január', 'Február', 'Március', 'Április',
      'Május', 'Június', 'Július', 'Augusztus',
      'Szeptember', 'Október', 'November', 'December'
    ];
    const selectedMonthName = monthNames[parseInt(selectedMonth)];
    fileName += `_${selectedMonthName}`;
  }

  fileName += '.pdf';

  if (isExport) {
    doc.save(fileName);
  } else {
    const to_email = window.prompt('Kérem, adja meg az e-mail címet amire küldeni szeretné az elszámolást:');
    const pdfBlob = doc.output('datauristring');
    const templateParams = {
      email_to: to_email,
      subject: currentDate,
      fileName: fileName,
      pdf: pdfBlob, // PDF letöltési URL
      
    };
   

      
    try {
      const templateId = 'template_yug1c6j';
      const userId = 'mkThDR7yQJmjik2dz';
      const serviceId = 'service_g83xz5s';
      const emailResult = await emailjs.send(serviceId, templateId, templateParams, userId);
      console.log('E-mail elküldve:', emailResult);

      
    } catch (error) {
      console.error('Nem sikerült elküldeni az e-mailt:', error);
      
    }
  }

 

 

  };

  return (
    <div className="ticket-logs-container">
      <h1 className="ticket-logs-heading">Eladott Jegyek Naplója</h1>
      
      <div className="filter-options">
        <label htmlFor="filterSender">Küldő szűrése:</label>
        <input
          type="text"
          id="filterSender"
          value={filterSender}
          onChange={(e) => setFilterSender(e.target.value)}
          placeholder="Küldő email címe"
        />
      </div>
      <div className="sorting-options">
        <label htmlFor="sortingOption">Rendezés:</label>
        <select
          id="sortingOption"
          value={sortingOption}
          onChange={(e) => setSortingOption(e.target.value)}
        >
          <option value="time">Időrend</option>
          <option value="event">Események</option>
        </select>
      </div>
      
      <div className="month-filter">
        <label htmlFor="monthFilter">Szűrés hónapra:</label>
        <select id="monthFilter" value={selectedMonth} onChange={handleMonthFilterChange}>
          <option value="">Összes hónap</option>
          <option value="0">Január</option>
          <option value="1">Február</option>
          <option value="2">Március</option>
          <option value="3">Április</option>
          <option value="4">Május</option>
          <option value="5">Jűnius</option>
          <option value="6">Július</option>
          <option value="7">Agusztus</option>
          <option value="8">Szeptember</option>
          <option value="9">Október</option>
          <option value="10">November</option>
          <option value="11">December</option>
        </select>
      </div>
      
      <div className="event-filter">
        <label htmlFor="eventFilter">Szűrés eseményre:</label>
        <select id="eventFilter" value={selectedEvent} onChange={handleEventFilterChange}>
          <option value="">Összes esemény</option>
          {eventNames.map((eventName, index) => (
            <option key={index} value={eventName}>
              {eventName}
            </option>
          ))}
        </select>
      </div>
      
      <table className="ticket-logs-table">
        <thead>
          <tr>
            <th>Időpont</th>
            <th>Esemény neve</th>
            <th>Küldő</th>
            <th>Címzett</th>
            <th>Jegy Ára</th>
            <th>Állapot</th>
          </tr>
        </thead>
        <tbody>
          {filterLogsByMonthAndEvent().map((log) => (
            <tr key={log.ticketId} className={log.eventActiveInactive === 'Aktív' ? 'ticket-active' : 'ticket-inactive'}>
              <td>{new Date(log.sold_date).toLocaleString()}</td>
              <td>{log.partyName}</td>
              <td>{log.senderEmail}</td>
              <td>{log.email_to}</td>
              <td>{log.ticketPrice} Ft</td>
              <td>{log.eventActiveInactive}</td>
            </tr>
          ))}
        </tbody>
      </table>
      
      <div className="summary">
        <p className="summary-text">Összesen <b>{totalTicketsSoldInMonthAndEvent}</b> jegyet adtak el.</p>
        <p className="summary-text">Összérték: <b>{totalRevenueInMonthAndEvent} Ft</b></p>
        <p className="summary-text">Részesedés: <b>{partnerRevenue.toFixed(2)} Ft</b> ({(partnerShare * 100).toFixed(2)}%)</p>
        
        <button onClick={() => generatePDF(true)}>Exportálás (PDF)</button>
        <button onClick={() => generatePDF(false)}>Küldés e-mailben (PDF)</button>
      </div>
    </div>
  );
};

export default TicketLogs;

