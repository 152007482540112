import React, { useState, useEffect, useContext } from 'react';
import { getFirestore, collection, getDocs, deleteDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import {  Context } from '../firebase';
import { useNavigate } from 'react-router-dom';

import '../style/Users.css';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [newDisplayName, setNewDisplayName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newRole, setNewRole] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [monthlyRevenue, setMonthlyRevenue] = useState(0);
  const [monthlySoldTickets, setMonthlySoldTickets] = useState(0);

  const navigate = useNavigate();
  const userData = useContext(Context);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersRef = collection(getFirestore(), 'users');
      const querySnapshot = await getDocs(usersRef);
      const userData = [];
      querySnapshot.forEach((doc) => {
        userData.push({ id: doc.id, ...doc.data() });
      });
      setUsers(userData);
    };

    fetchUsers();
  }, []);

  const handleDeleteUser = async (userId, email) => {
    try {
      await deleteDoc(doc(getFirestore(), 'users', userId));
      setUsers(users.filter((user) => user.id !== userId));
      setAlertMessage('Felhasználó sikeresen törölve.');
      setTimeout(() => {
        setAlertMessage('');
      }, 3000);
    } catch (error) {
      console.log('Hiba történt a felhasználó törlésekor:', error);
    }
  };

  const updateUserRole = async (userId, newRole) => {
    try {
      const userRef = doc(getFirestore(), 'users', userId);
      await updateDoc(userRef, { role: newRole });
      setAlertMessage('Szerepkör sikeresen frissítve.');
      setTimeout(() => {
        setAlertMessage('');
      }, 3000);
    } catch (error) {
      console.log('Hiba történt a szerepkör frissítésekor:', error);
      throw error;
    }
  };

  const saveChanges = async (userId) => {
    try {
      const userRef = doc(getFirestore(), 'users', userId);
      const userSnapshot = await getDoc(userRef);
      const userData = userSnapshot.data();

      const updatedData = {
        displayName: newDisplayName !== '' ? newDisplayName : userData.displayName,
        email: newEmail !== '' ? newEmail : userData.email,
      };

      await updateDoc(userRef, updatedData);

      if (newRole !== '') {
        await updateUserRole(userId, newRole);
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === userId ? { ...user, ...updatedData, role: newRole } : user
          )
        );
      } else {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === userId ? { ...user, ...updatedData } : user
          )
        );
      }

      setAlertMessage('Változtatások sikeresen mentve.');
      setTimeout(() => {
        setAlertMessage('');
      }, 3000);

      setNewDisplayName('');
      setNewEmail('');
      setNewRole('');
    } catch (error) {
      console.log('Hiba történt az adatok mentésekor:', error);
    }
  };

  const handleRoleChange = (event) => {
    setNewRole(event.target.value);
  };

  const handleResetMonthlyData = async (userId) => {
    try {
      // Először lekérdezzük az aktuális felhasználói adatokat a Firestore-ból
      const userRef = doc(getFirestore(), 'users', userId);
      const userSnapshot = await getDoc(userRef);
      const userData = userSnapshot.data();
      let totalmoney=userData.total + monthlyRevenue;
      // A meglévő monthly_money és monthly_sold helyett használjuk a megfelelő állapotokat
      const updatedData = {
        monthly_money: 0,
        monthly_sold: 0
      };
  console.log("asd",userData.total + monthlyRevenue)
      // Frissítjük az adatokat a Firestore-ban
      await updateDoc(userRef, updatedData);
  
      // Visszaállítjuk az állapotokat a React komponensen belül
      setMonthlyRevenue(0);
      setMonthlySoldTickets(0);
  
      setAlertMessage('Sikeresen lenulláztad az e-havi bevételt/eladásokat.',3000);
    } catch (error) {
      console.log('Hiba történt az adatok frissítésekor:', error);
    }
  };

  return (
    <div className="container">
      <h2>Felhasználók listája</h2>
      {alertMessage && <div className="alert">{alertMessage}</div>}
      {users.map((user) => (
        <div key={user.id} className="user-card">
          <p>Felhasználó neve: {user.displayName}</p>
          <p>Email: {user.email}</p>
          <p>Szerepkör: {user.role}</p>
          <p>E-Havi Eladott jegyek száma: {user.monthly_sold} db</p>
          <p>E-Havi Termelt bevétel: {user.monthly_money} Ft.</p>
          <p>Összes Eladott jegyek száma: {user.ticket_sold} db</p>
          <p>Termelt bevétel: {user.total} Ft.</p>

          <input
            type="text"
            value={newDisplayName}
            onChange={(e) => setNewDisplayName(e.target.value)}
            placeholder="Új felhasználónév"
            className="edit-input"
          />
          <input
            type="email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            placeholder="Új e-mail cím"
            className="edit-input"
          />
          <select value={newRole} onChange={handleRoleChange} className="edit-input">
            <option value={user.role}>{user.role}</option>
            {user.role !== 'Admin' && <option value="Admin">Admin</option>}
            {user.role !== 'Promóter' && <option value="Promóter">Promóter</option>}
          </select>
          <div className="btn-container">
            <button className="btn btn-outline-danger" onClick={() => handleDeleteUser(user.id, user.email)}>Felhasználó törlése</button>
            <button className="btn btn-outline-success" onClick={() => saveChanges(user.id)}>Mentés</button>
            
        <button className="btn btn-outline-primary" onClick={() => handleResetMonthlyData(user.id)}>Nullázás</button>
      
          </div>
        </div>
      ))}
      
    </div>
  );
};

export default Users;
