import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "../style/Instagram.css";
import { Link } from 'react-router-dom';

const InstagramPosts = () => {
  const [posts, setPosts] = useState([]);
  const [welcomeText, setWelcomeText] = useState('');

  useEffect(() => {
    // Az Instagram API hívás inicializálása
    
    const fetchInstagramPosts = async () => {
      try {
        // Az Instagram Graph API végpont és hozzáférési token beállítása
        const endpoint = 'https://graph.instagram.com/v12.0/me/media';
        const token = 'IGQWRPQ2lIVFp2UFlOYXhFWFkteWlNdW1GV0VQMWhCR2FCYjM4aXJONVVUWUJOalFXSzhldmJpNWNla01tRkhsZAzNpX0tPQ1lyN3g2YmxQTjJ2ZAE1pSlJuYllvTGQzUDVBWUhTOS1lWi1yejRuWW15RXRwaDBBV2sZD';

        // API hívás a posztok lekérdezéséhez
        const response = await axios.get(endpoint, {
          params: {
            fields: 'id,caption,media_type,media_url,thumbnail_url,permalink,timestamp,username',
            access_token: token,
          },
        });

        setPosts(response.data.data);
      } catch (error) {
        console.error('Hiba az Instagram API kéréskor:', error);
      }
    };
    const welcomeTextAnimation = async () => {
      const originalText = 'Üdvözöljük a TicketZseni Weboldalán!';
      let currentIndex = 0;
      let animatedText = '';

      while (currentIndex < originalText.length) {
        animatedText += originalText[currentIndex];
        setWelcomeText(animatedText);
        currentIndex++;
        await new Promise(resolve => setTimeout(resolve, 50)); // Várakozás 100 ms-ig a következő karakter hozzáadásához
      }
    };
    

    welcomeTextAnimation();
    fetchInstagramPosts();
  }, []);
  

  return (
    <>
    <div className="banner">
        <p>{welcomeText}</p>
       
        <a id='megtekgomb' href="#insta">Hírek Megtekintése</a>
        
      </div>

      <div className="instagram-posts-container">
        <h2 id='insta'>Legfrisebb Hírek</h2>
        <div className="instagram-posts">
          {posts.map((post) => (
            <div
              key={post.id}
              className="instagram-post"
            >
              <div className="profile-info">
                <div className="profile-info-inner">
                  <h3 className="username">
                    {post.username}
                  </h3>
                </div>
              </div>
              <img
                src={post.media_url}
                alt={post.caption}
                className="post-image"
              />
              <div className="post-details">
                <div className="caption">
                  <p>
                    <strong>{post.username}</strong> {post.caption}
                  </p>
                </div>
                <div className="timestamp">
                  {new Date(post.timestamp).toLocaleString()}
                </div>
                {console.log(post)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default InstagramPosts;
