import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import CryptoJS from 'crypto-js';
import {  db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';


const Signup = () => {
  const [displayName, setDisplayName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [monthly_money, setMonthlyMoney] = useState(0);
  const [monthly_sold, setMonthlySold] = useState(0);
  const [role, setRole] = useState('');
  const [ticket_sold, setTicketSold] = useState(0);
  const [token, setToken] = useState('');
  const [confirmed, setConfirmed] = useState(false);

  const [total, setTotal] = useState(0);
  const [profileImage, setProfileImage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const data = urlParams.get("data");
    if (data) {
      const emailAndTokenHex = data;
      const emailAndToken = CryptoJS.enc.Hex.parse(emailAndTokenHex).toString(CryptoJS.enc.Utf8);
      const email = emailAndToken.split("|")[0];
      const token = emailAndToken.split("|")[1];
      setEmail(email);
      // Most itt rendelkezel az eredeti e-mail címmel és token-nel
      console.log("E-mail:", email);
      console.log("Token:", token);
    } else {
      console.log("Helytelen adatok");
      // Itt átirányíthatod a felhasználót egy hibaoldalra vagy másik oldalra.
      navigate('/');
    }
  }, []);
  




  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const auth = getAuth();
      const { user } = await createUserWithEmailAndPassword(auth, email, password);

      const storage = getStorage();
      const imageRef = ref(storage, `profile_images/${user.uid}`);
      await uploadBytes(imageRef, profileImage);

      const downloadURL = await getDownloadURL(imageRef);
      //auth.currentUser.profileImage=downloadURL;
      //setProfileImage(auth.currentUser.downloadURL)
      const userDocRef = doc(db, 'users', user.uid);
      await setDoc(userDocRef, {
        displayName: displayName,
        email: email,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        monthly_money: 0,
        monthly_sold: 0,
        role: 'Promóter',
        ticket_sold: 0,
        total: 0,
        confirmed: confirmed,
        token: user.uid,
        profileImage: downloadURL,
      });

      setDisplayName('');
      setPassword('');
      setProfileImage('');
      setMonthlyMoney(0);
      setMonthlySold(0);
      setRole('');
      setTicketSold(0);
      setToken('');
      setTotal(0);
      

      
      navigate('/Login');
    } catch (error) {
      console.error('Hiba történt a regisztráció során:', error);
    
    }
  };

  return (
    <div>
      <form onSubmit={handleFormSubmit}>
        <h2>Regisztráció</h2>
        <label>Felhasználónév</label>
        <input
          type="text"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          placeholder="Név"
          required
        />
        <label>Jelszó</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Jelszó"
          required
        />
        <label>Profilkép</label>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setProfileImage(e.target.files[0])}
          required
        />
        <button type="submit">Regisztráció</button>
      </form>
    </div>
  );
};

export default Signup;
