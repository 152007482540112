import React, { useState } from 'react';
import "../style/Apply.css"
import emailjs from 'emailjs-com';
import { useNavigate } from "react-router-dom";

const Apply = () => {
    const [formData, setFormData] = useState({
        email: '',
        phoneNumber: '',
        name: '',
        city: '',
        instagram: '',
        reason: '',
        age: 0,
        gender: 'Férfi', // Alapértelmezett érték lehet fiú, de megváltoztatható
      });
      const navigate = useNavigate();
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleSubmit = async(e) => {
        e.preventDefault();
        const templateId = 'template_x6keg4h';
        const userId = 'mkThDR7yQJmjik2dz';
        const serviceId = 'service_g83xz5s';

        const templateParams = {
          email_to: 'info.ticketzseniteam@gmail.com',
          subject: `Promóteri Jelentkezés`,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          name: formData.name,
          city: formData.city,
          instagram: formData.instagram,
          reason: formData.reason,
          age: formData.age,
          gender: formData.gender
        };

        navigate("/Success?i=apply")
            // HA ELŐGIZETEK AZ EMAILJS RE AKKOR EZ A KÓD RÉSZ KELL AZ EMAIL KÜLDÉSÉHEZ
       try {
          // const emailResult = await emailjs.send(serviceId, templateId, templateParams, userId);
          //     console.log('E-mail elküldve:', emailResult);
                
         } catch (error) {
        //   console.error('Nem sikerült elküldeni az e-mailt:', error);
         }
        // Itt kezeld adataidat, például küldd el egy API-nak vagy mentsd el őket
        console.log(formData);
      
  };

  return (
    <div className="apply-form">
    <h1>Promóternek való Jelentkezés</h1>
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Email:</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Telefonszám:</label>
        <input
          type="tel"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Név:</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Lakhely (város):</label>
        <input
          type="text"
          name="city"
          value={formData.city}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Instagram profil link:</label>
        <input
          type="text"
          name="instagram"
          value={formData.instagram}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Miért szeretnél promóter lenni?</label>
        <textarea
          name="reason"
          value={formData.reason}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Hány éves vagy?</label>
       <input
       type='number'
          name="age"
          value={formData.age}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Férfi vagy nő vagy?</label>
        <select
          name="gender"
          value={formData.gender}
          onChange={handleChange}
          required
        >
          <option value="Férfi">Férfi</option>
          <option value="Nő">Nő</option>
        </select>
      </div>
      <button type="submit">Jelentkezés</button>
    </form>
  </div>
  );
};

export default Apply;
