import React, { useState } from 'react';
import CryptoJS from 'crypto-js';
import '../style/ShowEvents.css';
import { v4 as uuidv4 } from 'uuid';
const CreateEmailSign = () => {
  const [email, setEmail] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const generateRandomToken = () => {
    // Generálj egy véletlen számot 6 számjeggyel
    return Math.floor(100000 + Math.random() * 900000);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Token generálása
    const token = uuidv4();

    // E-mail cím és token kódolása egy hexadecimális kódban
    const emailAndToken = email + "|" + token;
    const emailAndTokenHex = CryptoJS.enc.Hex.stringify(CryptoJS.enc.Utf8.parse(emailAndToken));
    

    // A link összeállítása a véletlen token-nel
    const link = `https://ticketzseni.hu/Register??data=${emailAndTokenHex}`;

    console.log('Generált link:', link);

   

    setEmail('');
  };

  return (
    <>
      <h1>Felhasználó regisztrációs e-mail küldése</h1>
      <form onSubmit={handleFormSubmit}>
        <label>
          E-mail cím:
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="pl: valaki@gmail.com"
          />
          <button type="submit" className='btn btn-outline-success'>
            Link generálása
          </button>
        </label>
      </form>
    </>
  );
};

export default CreateEmailSign;
