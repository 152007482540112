import React, { useEffect, useState } from 'react';
import { firestore } from '../firebase';
import '../style/LeaderBoard.css'; // Importáljuk a stílusfájlt
import { FaCrown } from 'react-icons/fa';

const LeaderBoard = () => {
  const [promoters, setPromoters] = useState([]);

  useEffect(() => {
    // Firestore-ból lekérdezés
    const fetchPromoters = async () => {
      try {
        const usersCollection = firestore.collection('users');
        const snapshot = await usersCollection.where('role', '==', 'Promóter').get();
        const promotersData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setPromoters(promotersData);
      } catch (error) {
        console.error('Error fetching promoters', error);
      }
    };

    fetchPromoters();
  }, []);

  const sortedPromoters = promoters.slice().sort((a, b) => b.monthly_sold - a.monthly_sold);

  return (
    <div className="container"> {/* használjuk a className attribútumot */}
      <h1 className="heading">Promóter Rangsor</h1>
      <table className="table">
        <thead>
          <tr>
            <th className="columnHeader">#</th>
            <th className="columnHeader">Profilkép</th>
            <th className="columnHeader">Promóter Neve</th>
            <th className="columnHeader">Havi Eladott Jegyek</th>
          </tr>
        </thead>
        <tbody>
  {sortedPromoters.map((promoter, index) => (
    <tr key={index} className="tableRow">
      <td className="cell">
        {index + 1}.
      </td>
      <td className="cell">
        <img src={promoter.profileImage} alt="Profilkép" className="profileImage" />
      </td>
      <td className="cell">
        {index === 0 ? (
          <span className="crownName">
            <FaCrown className='crownIcon' style={{ verticalAlign: 'middle', marginRight: '5px' }} />
            <span style={{ verticalAlign: 'middle' }}>{promoter.displayName}</span>
          </span>
        ) : (
          promoter.displayName
        )}
      </td>
      <td className="cell">{promoter.monthly_sold} db</td>
    </tr>
  ))}
</tbody>



      </table>
    </div>
  );
};

export default LeaderBoard;
