import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";
import { Navbar, Nav, Button } from "react-bootstrap";
import { GetUserByEmailWhenLoggedIn, auth } from "../firebase";
import "../style/Navbar.css";
import defaultProfileImage from "../img/def_profile.png";
import { CartElement } from "@stripe/react-stripe-js";

const NavBar = () => {
  const [userData, setUserData] = useState(null);
  const [rolereg, setRolereg] = useState(false);

  const [profileMenuOpen, setProfileMenuOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchUserData = async () => {
      const data = await GetUserByEmailWhenLoggedIn();
      setUserData(data);
      console.log(data);

   //   if (auth.currentUser === null && location.pathname !== '/') {
   //     navigate("/Login");
   //   }
   //   if (data === null && location.pathname !== '/') {
   //     navigate("/Login");
   //   }
   if(data===null)
   {
     navigate("/")
   }
   
    };

    fetchUserData();
  }, []);

  

  useEffect(() => {
    if (userData && userData.role !== "Admin") {
      setRolereg(true);
    } else {
      setRolereg(false);
    }
  }, [userData]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/Login");
    } catch (error) {
      console.log(error);
    }
  };

  const email_regist = () => {
    navigate("/Create_Email");
  };
  const confirm_users = () => {
    navigate("/Confirm_Users");
  };

  const show_users = () => {
    navigate("/Users");
  };

  const home = () => {
    navigate("/Dashboard");
  };

  const new_event = () => {
    navigate("/New_Event");
  };

  const show_events = () => {
    navigate("/Show_Events");
  };

  const ticket_activate = () => {
    navigate("/Ticket_Activate");
  };

  const profile = () => {
    navigate("/Profile");
  };

  const log = () => {
    navigate("/Tickets_Log");
  };
  const leaderboard = () => {
    navigate("/Leaderboard");
  };



  return (
    <Navbar expand="lg ">
      <Navbar.Toggle aria-controls="basic-navbar-nav " />
      <Navbar.Collapse id="basic-navbar-nav ">
        <Nav className="me-auto">
          {location.pathname === '/Apply' && (
            <Button variant="outline-primary" onClick={() => navigate("/")}>
              Kezdőlap
            </Button>
          )}
  
          {userData && auth.currentUser && (
            <>
              <Button variant="outline-primary" onClick={home}>
                Home
              </Button>
              <Button variant="outline-primary" onClick={show_users} hidden={rolereg}>
                Felhasználók Listázása
              </Button>
              <Button variant="outline-primary" onClick={email_regist} hidden={rolereg}>
                Új Felhasználó
              </Button>
              <Button variant="outline-primary" onClick={new_event} hidden={rolereg}>
                Új Esemény
              </Button>
              <Button variant="outline-primary" onClick={show_events} hidden={rolereg}>
                Események Listázása
              </Button>
              <Button variant="outline-primary" onClick={ticket_activate}>
                Jegy Aktiválás
              </Button>
              <Button variant="outline-primary" onClick={log} hidden={rolereg}>
                Log
              </Button>
              <Button variant="outline-primary" onClick={confirm_users} hidden={rolereg}>
                Megerősítés
              </Button>
              <Button variant="outline-primary" onClick={leaderboard}>
                Leaderboard
              </Button>
            </>
          )}
        </Nav>
  
        <Nav className="ms-auto">
          {location.pathname === '/Apply' && (
            <Button variant="outline-primary" onClick={() => navigate("/Login")}>
              Login
            </Button>
          )}
  
          {location.pathname === '/Webshop' && (
            <Button variant="outline-primary" onClick={() => navigate("/Cart")}>
              Kosár
            </Button>
          )}
  
          {location.pathname === '/' && (
            <Nav id="mainpage">
              <Button variant="outline-primary" onClick={() => navigate("/Login")}>
                Login
              </Button>
            </Nav>
          )}
  
          {userData && auth.currentUser && (
            <div className="profile-info">
              <Button
                variant="link"
                className="profile-button"
                onClick={() => setProfileMenuOpen(!profileMenuOpen)}
              >
                <img
                  src={userData.profileImage || defaultProfileImage}
                  alt="Profile"
                  className="profile-image rounded-circle"
                  style={{ width: "40px", height: "40px" }}
                />
              </Button>
              {profileMenuOpen && (
                <div className="profile-menu">
                  <Button variant="outline-primary" onClick={() => { profile(); setProfileMenuOpen(false); }}>
                    Profil
                  </Button>
                  <Button variant="outline-danger" onClick={handleLogout}>
                    Kijelentkezés
                  </Button>
                </div>
              )}
            </div>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
  
};

export default NavBar;
