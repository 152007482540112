import './App.css';
import Dashboard from './auth/Dashboard';
import SignIn from './auth/SignIn';
import SignUp from './auth/SignUp';

import PrivateRoutes from './utils/PrivateRoutes';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Context } from './firebase';
import { useEffect, useState } from 'react';
import { GetUserByEmailWhenLoggedIn } from './firebase';
import Users from './auth/Users';
import Navbar from './auth/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import ShowNavbar from './auth/ShowNavbar';
import New_Event from './auth/NewEvent';
import ShowEvents from './auth/ShowEvents';
import CreateEmailSign from './auth/CreateEmailSign';
import TicketActivate from './auth/TicketActivate';
import Profile from './auth/Profile';

import TicketLogs from './auth/TicketLogs';
import LeaderBoard from './auth/LeaderBoard';

import Index from './auth/Index';
import Apply from './auth/Apply';
import TicketPage from './auth/TicketPage';

import Success from './auth/comps/Success';
import ConfirmUsers from './auth/ConfirmUsers';



const App = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const data = await GetUserByEmailWhenLoggedIn();
      setUserData(data);
    };

    fetchUserData();


    
  }, []);

  return (

    <div className='App '>
    
    <BrowserRouter>
    <ShowNavbar>
    <Navbar />
    </ShowNavbar>
    <Context.Provider value={userData}>
    <Routes>
  <Route element={<Index />} path="/" />
  <Route element={<Apply />} path="/Apply" />

  <Route element={<SignIn />} path="/Login" />
  <Route element={<SignUp />} path="/Register" />

 
  <Route element={<PrivateRoutes />}>
   
    
          <Route element={<Success />} path="/Success" />
            <Route element={<Dashboard />} path="/Dashboard" />
            <Route element={<ConfirmUsers />} path="/Confirm_Users" />
            <Route element={<TicketPage />} path="/TicketPage" />
            <Route element={<CreateEmailSign />} path="/Create_Email" />
            <Route element={<Users />} path="/Users" />
            <Route element={<New_Event />} path="/New_Event" />
            
            <Route element={<ShowEvents />} path="/Show_events" />
            <Route element={<TicketActivate />} path="/Ticket_Activate" />
            <Route element={<Profile />} path="/Profile" />
            <Route element={<TicketLogs />} path="/Tickets_Log" />
            <Route element={<LeaderBoard />} path="/Leaderboard" />
   
  </Route>
</Routes>
    </Context.Provider>
  
</BrowserRouter>
    </div>

  );
};

export default App;
