import React, { useEffect, useState } from 'react';
import { GetUserByEmailWhenLoggedIn, auth, storage, firestore } from '../firebase';

import "../style/Profile.css";
import showAlert from './comps/alert';

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [newProfileImage, setNewProfileImage] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const data = await GetUserByEmailWhenLoggedIn();
      setUserData(data);
    };

    fetchUserData();
  }, []);

  const handleChangePassword = async () => {
    try {
      const user = auth.currentUser;

      if (newPassword.trim() === '') {
        showAlert('Az új jelszó nem lehet üres', 'my-alert-error');
        return;
      }

      await user.updatePassword(newPassword);
      showAlert('Sikeresen megváltoztattad a jelszavad!', 'my-alert-success');
      setNewPassword('');
    } catch (error) {
      console.error('Hiba a jelszóváltoztatás során:', error);
    }
  };

  const handleConfirmChangePassword = () => {
    if (window.confirm('Biztosan meg szeretnéd változtatni a jelszót?')) {
      handleChangePassword();
    }
  };

  const handleProfileImageChange = async () => {
    try {
      if (!newProfileImage) {
        showAlert('Válassz ki egy képet a profilkép frissítéséhez!', 'my-alert-error');
        return;
      }
  
      const user = auth.currentUser;
      const storageRef = storage.ref();
      
      // Először lekérdezzük a régi profilkép fájljának elérési útját a Firestore-ból
      const userDocRef = firestore.collection('users').doc(user.uid);
      const doc = await userDocRef.get();
      const oldProfileImageURL = doc.data().profileImage;
      
      // Töröljük a régi profilkép fájlt a Firebase Storage-ból
      if (oldProfileImageURL) {
        const oldImageRef = storage.refFromURL(oldProfileImageURL);
        
        await oldImageRef.delete();
      }
  
      // Feltöltjük az új profilképet
      const imageRef = storageRef.child(`profile_images/${user.uid}`);
      await imageRef.put(newProfileImage);
      const downloadURL = await imageRef.getDownloadURL();
 
  
      // Frissítjük a Firestore dokumentumot az új letöltési URL-lel
      await userDocRef.update({ profileImage: downloadURL });
  
      showAlert('Profilkép sikeresen frissítve!', 'my-alert-success');
      setNewProfileImage(null);
    } catch (error) {
      console.error('Hiba történt a profilkép frissítésekor:', error);
      showAlert('Hiba történt a profilkép frissítésekor', 'my-alert-error');
    }
  };
  

  return (
    <div className="container">
      {userData ? (
        <div className="profile-card">
          <div className="profile-info">
            <p className="profile-label">Felhasználónév:</p>
            <p className="profile-value"><b>{userData.displayName || 'N/A'}</b></p>
            
            <p className="profile-label">E-mail:</p>
            <p className="profile-value"><b>{userData.email || 'N/A'}</b></p>
            
            <p className="profile-label">Beosztás:</p>
            <p className="profile-value"><b>{userData.role || 'N/A'}</b></p>
            
            {/* ... other profile info ... */}
          </div>
          
          <input
            type="password"
            placeholder="Új jelszó"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <button  onClick={handleConfirmChangePassword} className="btn btn-outline-success">Jelszó Változtatás</button>

          <input
            type="file"
            accept="image/*"
            onChange={(e) => setNewProfileImage(e.target.files[0])}
          />
          <button  onClick={handleProfileImageChange} className="btn btn-outline-success">Profilkép cseréje</button>
        </div>
      ) : (
        <p>Nincs bejelentkezett felhasználó</p>
      )}
    </div>
  );
};

export default Profile;
