import { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { auth } from '../firebase';

const PrivateRoutes = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [hasValidToken, setHasValidToken] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      setLoading(false);

      // Itt ellenőrizzük a token érvényességét és beállítjuk az állapotot ennek megfelelően
      const token = new URLSearchParams(window.location.search).get('token');
      setHasValidToken(!!token);
    });

    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    // Nincs bejelentkezve
    if (hasValidToken) {
      // Ha van érvényes tokenje, akkor átirányítjuk a "/register"-re
      return <Navigate to="/Register" />;
    } else {
      // Ha nincs érvényes tokenje, akkor átirányítjuk a "/Dashboard"-ra vagy bárhova, ahova szükséges
      return <Navigate to="/Login" />;
    }
  }

  // Be van jelentkezve, engedélyezzük a további útvonalak elérését
  return <Outlet />;
};

export default PrivateRoutes;
