import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { auth, db } from '../firebase';
import jsPDF from 'jspdf';
import { v4 as uuidv4 } from 'uuid';
import QRCode from 'qrcode';

import html2canvas from 'html2canvas';

//import SendTickets from "../../email-server/server"


const TicketPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [eventInformation, setEventInformation] = useState(null);
  const [ticketPrices, setTicketPrices] = useState([]);
  const [selectedPriceIndex, setSelectedPriceIndex] = useState(0);
  const [emailToSend, setEmailToSend] = useState('');
  const [vezeteknev, setVezetknev] = useState('');
  const [keresztnev, setKeresztnev] = useState('');
  const [iranyitoszam, setIranyitoszam] = useState('');
  const [lakcim, setLakcim] = useState('');
  const [tel, setTel] = useState('');
  const [eventId, setEventId] = useState('');
  const [eventName, setEventName] = useState('');
  const [isSoldOut, setIsSoldOut] = useState(false);
  const [soldTicketsCount, setSoldTicketsCount] = useState(0);
  const [online1db, setOnline1db] = useState(0);
  const [online2db, setOnline2db] = useState(0);
  const [online3db, setOnline3db] = useState(0);
  const navigate = useNavigate();

  const handleSendTicket = async (e) => {
    e.preventDefault();
    try {
      const selectedPrice = ticketPrices[selectedPriceIndex];
      const pdfBlob = await createDocument(eventInformation.eventName, selectedPrice, emailToSend, eventInformation.eventTime, eventInformation.eventPlace, auth.currentUser.email);
      const pdfFile = new File([pdfBlob], "ticket.pdf", { type: "application/pdf" });
      await sendEmail(emailToSend, "TicketZseni ---> Jegy", "EZ a jegyed kocsog", pdfFile);
      navigate("/Success?i=ticket");
    } catch (error) {
      console.error('Hiba történt a jegy küldése közben:', error);
    }
  };


  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const partyId = queryParams.get('id');
    setEventId(partyId);
  }, []);

  useEffect(() => {
    if (eventId) {
      const fetchEventInformation = async () => {
        try {
          const eventRef = db.collection('events').doc(eventId);
          const eventDoc = await eventRef.get();
          if (eventDoc.exists) {
            const eventData = eventDoc.data();
            setEventInformation(eventData);
            setIsSoldOut(eventData.soldOut);
            setEventName(eventData.eventName);
            setOnline1db(eventData.online1_db)
            setOnline2db(eventData.online2_db)
            setOnline3db(eventData.online3_db)
          } else {
            console.error('Az esemény nem található.');
          }
        } catch (error) {
          console.error('Hiba történt az esemény információk lekérdezése közben:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchEventInformation();
    }
  }, [eventId]);

  useEffect(() => {
    if (eventId) {
      const fetchSoldTicketsCount = async () => {
        try {
          const soldTicketsRef = db.collection('tickets').where('partyName', '==', eventName);
          const soldTicketsSnapshot = await soldTicketsRef.get();
          await setSoldTicketsCount(soldTicketsSnapshot.docs.length);

          if(online1db>=soldTicketsSnapshot.docs.length){
            setTicketPrices([
              eventInformation.eventPriceOnline1,
              eventInformation.eventPriceOnline2,
              eventInformation.eventPriceOnline3,
            ]);
          } else if (online1db <= soldTicketsSnapshot.docs.length) {
            setTicketPrices([
              "ELFOGYOTT",
              eventInformation.eventPriceOnline2+"Ft",
              eventInformation.eventPriceOnline3 +"Ft",
            ]);
          } else if (online2db+online1db <= soldTicketsSnapshot.docs.length) {
            setTicketPrices([
              eventInformation.eventPriceOnline3,
            ]);
          } else if(online1db+online2db+online3db>=soldTicketsSnapshot.docs.length) {
            setTicketPrices([]);
            setIsSoldOut(true);
          }
        } catch (error) {
          console.error('Hiba történt az eladott jegyek számának lekérdezése közben:', error);
        }
      };

      fetchSoldTicketsCount();
    }
  }, [eventId, eventName]);

  const createDocument = async (eventName, selectedPrice, email, eventTime, eventPlace, senderEmail) => {
    try {
        let ticketId = uuidv4(); // Alapértelmezett ticketId generálása

        let ticketRef = db.collection('tickets').doc(ticketId);
        let ticketSnapshot = await ticketRef.get();

        // Ellenőrizzük, hogy az adott ticketId már létezik-e az adatbázisban
        while (ticketSnapshot.exists) {
            console.log(`Az ${ticketId} már létezik az adatbázisban. Új ticketId generálása...`);
            ticketId = uuidv4(); // Új ticketId generálása
            ticketRef = db.collection('tickets').doc(ticketId);
            ticketSnapshot = await ticketRef.get();
        }

        const qrCodeData = JSON.stringify({ ticketId, partyName: eventName });
        const qrCodeBase64 = await QRCode.toDataURL(qrCodeData);

        // PDF generálása
        const pdfBlob = await generatePDF(eventName, selectedPrice, eventTime, eventPlace, ticketId, qrCodeBase64);

        // Firestore adatok
        const ticketFirestoreData = {
            email_to: email,
            partyName: eventName,
            partyDateTime: eventTime,
            senderEmail: senderEmail,
            ticketPrice: parseInt(selectedPrice),
            eventActiveInactive: 'Inaktív',
            sold_date: new Date().toISOString(),
            ticketId: ticketId,
            qrCodeBase64: qrCodeBase64,
        };

        console.log('Firestore adatok:', ticketFirestoreData);

        // Firestore adatok feltöltése
        await ticketRef.set(ticketFirestoreData);

        console.log('Jegyadatok hozzáadva a Firestore adatbázishoz.');
        return pdfBlob; // Visszatérünk a PDF blob-bal
    } catch (error) {
        console.error('Hiba történt a dokumentum mentése közben:', error.message);
    }
};







const generatePDF = async (eventName, selectedPrice, eventTime, eventPlace, ticketId, qrCodeBase64) => {
    return new Promise((resolve, reject) => {
        // Create a container for the content
        const containerHtml = `
            <html>
                <head>
                    <style>
                        body {
                            margin: 0;
                            padding: 0;
                            font-family: Arial, sans-serif;
                        }
                        #ticket-container {
                            width: 190mm; /* Adjust width to fit within A4 dimensions */
                            height: 100mm; /* Adjust height as needed */
                            padding: 10mm;
                            box-sizing: border-box;
                            border: 2px solid #333;
                            border-radius: 8px;
                            display: flex;
                            flex-direction: row;
                            background-color: #f9f9f9;
                            position: relative;
                        }
                        .ticket-content {
                            width: 70%;
                            padding-right: 10mm;
                            box-sizing: border-box;
                            border-right: 2px solid #333;
                        }
                        .ticket-header {
                            font-size: 18px;
                            font-weight: bold;
                            color: #333;
                            margin-bottom: 5mm;
                        }
                        .text-frame {
                            margin-bottom: 10mm;
                        }
                        .event {
                            font-size: 16px;
                            font-weight: bold;
                            color: #333;
                            margin-bottom: 4mm;
                        }
                        .date, .name, .ticket-id {
                            font-size: 12px;
                            color: #666;
                            margin-bottom: 2mm;
                        }
                        .ticket-id {
                            font-weight: bold;
                        }
                        .qr-holder {
                            width: 30%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        .qr-code {
                            width: 180px;
                            height: 180px;
                            border: 1px solid #ddd;
                            border-radius: 5px;
                        }
                        .footer {
                            font-size: 12px;
                            color: #666;
                            margin-top: 10mm;
                        }
                    </style>
                </head>
                <body>
                    <div id="ticket-container">
                        <div class="ticket-content">
                            <div class="ticket-header">Belépésre Feljogosító Nyomtatvány</div>
                            <div class="text-frame">
                                <div class="event">${eventName}</div> 
                                <div class="date">Dátum: ${new Date(eventTime).toLocaleString()}</div>
                                <div class="name">Helyszín: ${eventPlace}</div>
                                <div class="ticket-id">#${ticketId}</div>
                                <div class="ticket-id">Jegy Ára: ${selectedPrice} Ft</div>
                            </div>
                            <div class="footer">Kérjük, őrizze meg a jegyet!</div>
                        </div>
                        <div class="qr-holder">
                            <img class="qr-code" src="${qrCodeBase64}" />
                        </div>
                    </div>
                </body>
            </html>
        `;

        // Create a hidden container for rendering
        const container = document.createElement('div');
        container.innerHTML = containerHtml;
        container.style.position = 'absolute';
        container.style.top = '-9999px';
        document.body.appendChild(container);

        // Use html2canvas to convert HTML to image
        html2canvas(container).then(canvas => {
            // Create a new jsPDF instance
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: [210, 297] // A4 size in mm
            });

            // Calculate the scaling factor to fit the content
            const imgData = canvas.toDataURL('image/png');
            const pdfWidth = 210; // A4 width
            const pdfHeight = 297; // A4 height
            const imgWidth = canvas.width * 0.75; // Scale to fit within PDF
            const imgHeight = canvas.height * 0.75; // Scale to fit within PDF

            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, (imgHeight * pdfWidth) / imgWidth); // Fit the content

            // Output PDF as a Blob
            const pdfBlob = pdf.output('blob');
            document.body.removeChild(container);
            resolve(pdfBlob);
        }).catch(error => {
            document.body.removeChild(container);
            reject(error);
        });
    });
};





const sendEmail = async (to, subject, text, pdfBlob) => {
  try {
    const formData = new FormData();
    formData.append('to', to);
    formData.append('subject', subject);
    formData.append('text', text);
    formData.append('pdf', pdfBlob, 'ticket.pdf'); // PDF csatolása

    const response = await fetch('http://localhost:5000/send-email', {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      const result = await response.text();
      console.log(result);
    } else {
      console.error('Az e-mail küldése sikertelen volt:', response.statusText);
    }
  } catch (error) {
    console.error('Hiba történt az e-mail küldése közben:', error);
  }
};





// await SendTickets(eventInformation.eventName, selectedPrice, emailToSend, eventInformation.eventTime, eventInformation.eventPlace,auth.currentUser.email)
  

  return (
    <div>
      <h1>Jegy Kiküldéséhez Szükséges Adatok</h1>
      {loading ? (
        <p>Betöltés...</p>
      ) : (
        eventInformation ? (
          <div>
            <label>Válassz Jegyet:</label>
            <select
  value={selectedPriceIndex}
  onChange={(e) => setSelectedPriceIndex(e.target.value)}
>
  {ticketPrices.map((price, index) => {
    const optionValue = index.toString();
    const isDisabled = price === "ELFOGYOTT"; // Ellenőrizzük, hogy az aktuális ár "ELFOGYOTT" string-e

    return (
      <option
        key={index}
        value={optionValue}
        disabled={isDisabled} // Ha az ár "ELFOGYOTT", akkor letiltva legyen az opció
      >
        { `Elővételes ${index + 1}: ${price}`}
      </option>
    );
  })}
</select>


            {isSoldOut ? (
              <p>A jegyek elfogytak</p>
            ) : (
              <form onSubmit={handleSendTicket}>
                <div>
                  <label for="vezeteknev">Vezetéknév</label>
                  <input
                    type="text"
                    id="vezeteknev"
                    value={vezeteknev}
                    onChange={(e) => setVezetknev(e.target.value)}
                    placeholder="Vezetéknév"
                    disabled={isSoldOut}
                    required
                  />
                </div>

                <div>
                  <label for="keresztnev">Keresztnév:</label>
                  <input
                    type="text"
                    id="keresztnev"
                    value={keresztnev}
                    onChange={(e) => setKeresztnev(e.target.value)}
                    placeholder="Keresztnév"
                    disabled={isSoldOut}
                    required
                  />
                </div>

                <div>
                  <label for="iranyitoszam">Irányítószám</label>
                  <input
                    type="number"
                    id="iranyitoszam"
                    value={iranyitoszam}
                    onChange={(e) => setIranyitoszam(e.target.value)}
                    placeholder="Irányítószám"
                    disabled={isSoldOut}
                    required
                  />
                </div>

                <div>
                  <label for="lakcim">Lakcím:</label>
                  <input
                    type="text"
                    id="lakcim"
                    value={lakcim}
                    onChange={(e) => setLakcim(e.target.value)}
                    placeholder="Város, utca, Házszám"
                    disabled={isSoldOut}
                    required
                  />
                </div>

                <div>
                  <label for="tel">Vevő Telefonszám</label>
                  <input
                    type="tel"
                    id="tel"
                    value={tel}
                    onChange={(e) => setTel(e.target.value)}
                    placeholder="Telefonszám"
                    disabled={isSoldOut}
                    required
                  />
                </div>

                <div>
                  <label for="emailToSend">Ahova a Jegyet Küldi a Rendszer</label>
                  <input
                    type="email"
                    id="emailToSend"
                    value={emailToSend}
                    onChange={(e) => setEmailToSend(e.target.value)}
                    placeholder="E-mail cím"
                    disabled={isSoldOut}
                    required
                  />
                </div>

                <button type="submit" disabled={isSoldOut} class="btn btn-primary">
                  Küldés
                </button>
              </form>
            )}
          </div>
        ) : (
          <p>Esemény információk betöltése...</p>
        )
      )}
    </div>
  );
};

export default TicketPage;
