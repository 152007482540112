import React, { useState, useEffect } from 'react';
import { Redirect, useNavigate } from 'react-router-dom';
import "../../style/Success.css"

const Success = () => {
    const [redirect, setRedirect] = useState(false);
    const [countdown, setCountdown] = useState(3);
    const [message, setMessage] = useState(3);
    const [type, setType] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
      const queryParams = new URLSearchParams(window.location.search);
      const type = queryParams.get('i');
      setType(type)
      if(type==="apply")
      {
        setMessage("A jelentkezésedet továbbítottuk a vezetőségnek!")
      }
      else if(type==="ticket")
      {
        setMessage("A jegy megtalálta új gazdáját!");
      }
      else if(type==="confirm")
      {
        setMessage("Már csak az adminisztrátorok megerősítése van hátra!");
      }
    }, []);
    useEffect(() => {
      const countdownInterval = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1);
      }, 1000);
  
      setTimeout(() => {
        setRedirect(true);
        clearInterval(countdownInterval);
      }, 3000);
  
      return () => {
        clearInterval(countdownInterval);
      };
    }, []);
   

    return (
       
      <div class="container">
    <div class="success-text">
        <b> Zseniális!</b><br/>{message}
        </div>
        <p>Visszairányítás az előző oldalra <span id="countdown">{countdown}</span>  másodperc múlva.</p>
        {redirect && type==="apply" && navigate("/")||redirect && type==="ticket" && navigate("/Dashboard") ||redirect && type==="confirm" && navigate("/Login")} 
      </div>
      
    );
  };
export default Success
